.custom_slogan {
    background-color: #3b3b3b;
    width: 100%;
    padding: 2rem 0 2rem 0;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slogan_element {
    display: block;
    margin-right: 1rem;
    margin-left: 2rem;
}
.slogan_element::selection {
    background-color: red;
    border: 2px solid red;
}

.arrow_wrapper {
    background-color: rgb(255,87,34);
    padding: .5rem;
    cursor: pointer;
}

.arrow_wrapper:hover {
    transform: rotate(45deg);
    transition: all .5s ease;
}


.arrow_wrapper:hover .arrow {
    transform: rotate(-45deg);
    transition: all .5s ease;
}


