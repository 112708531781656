.custom_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.custom_input > * {
    margin-outside: .5rem !important;
}
.excel_download{
    float:right;
    margin-bottom: 10px;
}
.elm-datepicker-wrapper .react-datepicker-wrapper{
    width: 100%;
}
.input-group-addon {
    padding: 6px 12px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    font-size: 18px;
}
.text_green {
    color: #bf1e2e !important;
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
}

.input_error {
    border: 1px solid red !important;
}

.form-label {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
}

.label_error {
    color: red !important;
}

.form-control {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
}

.form-control-sm {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
}

.user_profile_text_green {
    color: #bf1e2e !important;
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    font-size: 1rem !important;
    font-weight: 600;
    line-height: 1.5;
}

.custom_font {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
    line-height: 1.5;
    font-weight: 600;
}

.font_helvetica {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    color: #161c2d !important;
}


.updates-title {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
    font-weight: bold;
    color: #212529 !important;
    font-size: 1rem !important;
}


.custom-border-box img :hover {
    transform: translateY(-3px);
    transition: all .3s ease;
    box-sizing: border-box;
}

.cursor_pointer {
    cursor: pointer;
}

.creation-date {
    font-size: 14px !important;
}

.custom_toast svg {
    color: #bf1e2e !important;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.number-li {
    color: #7c7c7c;
    font-size: 6.5rem;
    line-height: .8461538462;
    opacity: .4;
    font-weight: 700;
}

.border-button {
    border-radius: 5px !important;
}

.font-700 {
    font-weight: 700;
}

.font-400 {
    font-weight: 400 !important;
}

.layout-wrapper {
    min-height: 100vh; /* will cover the 100% of viewport */
    display: block;
    position: relative;
    padding-bottom: 2rem; /* height of your footer */
}

.custom-simple-bar {
    max-height: 400px;
    box-shadow: 0 1px 2px 2px lightgrey;
    padding: 1.5rem .3rem;
    border-radius: 5px;
    font-size: 14px !important;
}

#simple-bar {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
    text-align: left;
    font-size: 1rem;
}

.simple-bar-text-bold {
    font-weight: 700;
    font-size: 14px;
}

#privacy-policy {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: 1rem;
    text-align: left;
}

#privacy-policy ul li {
    margin-left: 2rem;
}

.bold {
    font-weight: bold;
    font-size: 1.5rem;
}

.text-bold {
    font-weight: bold;
}

.custom_ol {
    font-size: 1.4rem;
    font-weight: 500;
}

#revycare {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
}

#condizioni {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    display: block;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1rem;
}

#protezione {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    display: block;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1rem;
}

#crediti {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    display: block;
    margin-left: 3rem;
    margin-right: 3rem;
    font-size: 1rem;
}

.font-12 {
    font-size: 12px;
}
.text-underline {
    text-decoration: underline;
}
.margin-bottom {
    margin-bottom: 2px !important;
}

.border-box {
    border: 1px solid black;
    padding: .5rem;
}

@media (max-width: 600px) {
    .max_width_input {
        max-width: 12rem !important;
    }
}


.custom_navigation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.space_bullet{
    padding-bottom: 1.8rem;
}

@media (min-width: 992px)
.me-lg-5 {
    margin-right: 3rem!important;
    padding-top: 3rem!important;
}